<template>
    <div>
      <div>
        <img src="/imgs/enroll.jpg" style="width:100%;"/>
      </div>
      <div style="padding:0.2em;font-weight:500;font-size:1.2em;">德国玛娅家居2021建博会入场登记</div>
      <div style="padding:0.2em;font-weight:400;">欢迎参观德国玛娅家居展位!</div>
      <div style="padding:0.2em;"><a href="https://www.maierhome.com.cn" target="_blank" style="color:#019384;">玛娅官网</a></div>
      <van-divider></van-divider>
      <div v-if="hasEnroll">
        <div style="color: red; font-size: 1.2em;margin-bottom:10px;"> 恭喜你，已完成登记!</div>
        <div style="font-size: 1.4em;font-weight: 500; margin-bottom:40px;"> 入场码: {{ enrollCode }}</div>
        <van-overlay :show="guide" @click="guide = false">
            <div style="position:absolute;right:15px;top:0px;"><img src="/imgs/guide.png" style="width:200px;" /></div>
        </van-overlay>
        <van-button type="info" text="邀请好友参展" @click="guide = true" />
      </div>
      <van-form @submit="onSubmit" v-if="NotEnroll">
        <van-field
          v-model="name"
          required
          name="name"
          label="姓名"
          placeholder="请填写姓名"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          readonly
          clickable
          required
          name="area"
          :value="area"
          label="城市"
          placeholder="点击选择城市"
          @click="showArea = true"
        />
        <van-popup v-model="showArea" position="bottom">
          <van-area
            :area-list="areaList"
            @confirm="onConfirm"
            @cancel="showArea = false"
          />
        </van-popup>
        <van-field
          v-model="mobile"
          required
          name="mobile"
          label="手机号"
          placeholder="请填写手机号"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field name="sex" label="性别" required>
          <template #input>
            <van-radio-group v-model="sex" direction="horizontal">
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
              <van-radio name="3">不想透露</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="company"
          required
          name="company"
          label="公司名称"
          placeholder="请填写公司名称"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          v-model="position"
          required
          name="position"
          label="职位"
          placeholder="请填写职位"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field name="reason" label="参展原因" required>
          <template #input>
            <van-radio-group v-model="reason">
              <van-radio name="1">我是参展商</van-radio>
              <van-radio name="2">我想选加盟品牌</van-radio>
              <van-radio name="3">材料供应商</van-radio>
              <van-radio name="4">我是工程开发或代理商，找合作品牌</van-radio>
              <van-radio name="5">我是设计师，看看最新产品，有机会合作</van-radio>
              <van-radio name="6">其他</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <div style="margin: 16px;">
          <van-button block type="info" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
</template>

<script>
import areaList from '../assets/area.js'
import { shareUrl, hideShare } from '../assets/wxshare.js'
import { Form, Picker, Button, Popup, Field, CellGroup, Cell, RadioGroup, Radio, Area, Toast, Divider, Loading, Dialog, Overlay } from 'vant'
export default {
  // 注册组件
  components: {
    [Form.name]: Form,
    [Picker.name]: Picker,
    [Button.name]: Button,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Area.name]: Area,
    [Popup.name]: Popup,
    [Toast.name]: Toast,
    [Divider.name]: Divider,
    [Loading.name]: Loading,
    [Overlay.name]: Overlay,
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      url: 'https://' + window.location.host + '/enroll',
      user: '',
      guide: false,
      sid: 0,
      formid: 1,
      hasEnroll: false,
      NotEnroll: false,
      enrollCode: '',
      auth_url: '',
      appid: 'wxc0ab42a05b7d0625',
      scope: 'snsapi_base',
      redirect_uri: '',
      openid: '',
      code: '',
      name: '',
      mobile: '',
      sex: '',
      reason: '',
      company: '',
      position: '',
      area: '',
      showArea: false,
      areaList: null,
      validFields: {
        name: '姓名',
        mobile: '手机号',
        sex: '性别',
        reason: '参展原因',
        company: '公司名称',
        position: '职位',
        area: '城市'
      }
    }
  },
  created () {
    document.title = '德国玛娅家居2021建博会入场登记'
    var sid = this.getUrlKey('sid')
    if (sid !== null) {
      sessionStorage.setItem('sid', sid)
    }
    this.userInfo = sessionStorage.getItem('user')
    if (this.userInfo === null) {
      this.code = this.getUrlKey('code')
      if (this.code === null) {
        this.redirect_uri = encodeURIComponent(window.location.href)
        this.auth_url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + this.appid + '&redirect_uri=' + this.redirect_uri + '&response_type=code&scope=' + this.scope + '&state=STATE#wechat_redirect'
        window.location.replace(this.auth_url)
        this.code = this.getUrlKey('code')
        sessionStorage.setItem('code', this.code)
      } else {
        this.checkHasEnroll({ code: this.code, formid: this.formid })
      }
    } else {
      var user = JSON.parse(sessionStorage.getItem('user'))
      this.openid = user.openid
      if (user.enroll_code === '') {
        this.NotEnroll = true
        hideShare(encodeURIComponent(window.location.href))
      } else {
        this.hasEnroll = true
        this.enrollCode = user.enroll_code
        shareUrl(encodeURIComponent(window.location.href), '德国玛娅家居2021建博会', '展位号：3A109\n时间：2021.03.24-26\n地点：上海虹桥•国家会展中心', 'https://citiking-web.oss-cn-beijing.aliyuncs.com/maier/share_logo.png')
      }
    }
    this.sid = sessionStorage.getItem('sid')
    this.areaList = areaList
  },
  mounted () {
  },
  methods: {
    onSubmit (values) {
      let valided = true
      values.name = this.name
      values.mobile = this.mobile
      values.sex = this.sex
      values.reason = this.reason
      values.company = this.company
      values.position = this.position
      values.area = this.area
      for (var k in values) {
        if (values[k] === '') {
          Toast('请填写' + this.validFields[k])
          valided = false
          break
        }
        if (!(/^1[3456789]\d{9}$/.test(values.mobile))) {
          Toast('请输入正确的手机号')
          valided = false
          break
        }
      }
      values.formid = this.formid
      values.openid = this.openid
      values.sid = this.sid
      if (valided) {
        this.enroll(values)
      }
    },
    enroll (params) {
      this.https.get('tools/enroll', params).then(response => {
        if (response.code === 0) {
          Dialog.alert({
            title: '提示',
            message: '提交成功，感谢你的支持！'
          }).then(() => {
            sessionStorage.setItem('user', JSON.stringify(response.data))
            window.location.replace(this.url + '?sid=' + response.data.sid)
          })
        } else {
          Toast(response.msg)
        }
      })
    },
    checkHasEnroll (params) {
      this.https.get('tools/check_enroll', params).then(response => {
        if (response.code === 0) {
          sessionStorage.setItem('user', JSON.stringify(response.data))
          if (response.data.enroll_code === '') {
            window.location.replace(this.url + '?sid=' + this.sid)
          } else {
            window.location.replace(this.url + '?sid=' + response.data.sid)
          }
        } else {
          Toast(response.code + ':' + response.msg)
        }
      })
    },
    onConfirm (values) {
      this.area = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/')
      this.showArea = false
    },
    getUrlKey (name) {
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || ['', ''])[1].replace(/\+/g, '%20')) || null
    }
  }
}
</script>
<style>
  .van-field__label {width: 5em !important;padding-left:10px;}
  .van-cell--required::before { left: 15px; }
  .van-radio { margin-top: 4px; margin-bottom: 4px; }
  .van-button--info {
    background-color: #019384;
    border: 1px solid #019384;
  }
  .van-radio__icon--checked .van-icon {
    background-color: #019384;
    border-color: #019384;
  }
</style>
